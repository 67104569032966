import { Box, IconButton, Drawer, List, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import logo from "../../assets/Img/logo/lemlogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { CloseOutlined } from "@mui/icons-material";
import back from "../../assets/Img/0X8A9309 copy 1.webp";
import Aos from "aos";
import "aos/dist/aos.css";

const Drawers = () => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <>
      <Drawer anchor="left" open={open} onClose={() => setOpen(!open)}>
        <Box
          sx={{
            bgcolor: "#fff",
            height: "100vh",
            width: "100vw",
            p: 3,
            boxSizing: "border-box",
            backgroundImage: `url('${back}')`,
            backgroundPosition: "-670px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Link to="/">
              <img src={logo} width={120} />
            </Link>
            <IconButton onClick={() => setOpen(!open)} sx={{ bgcolor: "#ddd" }}>
              <CloseOutlined sx={{ color: "#000", fontSize: "30px" }} />
            </IconButton>
          </Box>

          <Box align="right" sx={{ mt: 8 }}>
            <Link to="/">
              <Typography
                data-aos="fade-left"
                sx={{ fontFamily: "itim", fontSize: "30px", color: "#333" }}
              >
                Home
              </Typography>
              <Box sx={{ height: "2px", bgcolor: "#000", width: "10%" }} />
              <Box
                sx={{ height: "1px", mt: 0.2, bgcolor: "#000", width: "5%" }}
              />
            </Link>
            <Link to="/about">
              <Typography
                data-aos="fade-left"
                sx={{
                  fontFamily: "itim",
                  fontSize: "30px",
                  mt: 3,
                  color: "#333",
                }}
              >
                About
              </Typography>
              <Box sx={{ height: "2px", bgcolor: "#000", width: "10%" }} />
              <Box
                sx={{ height: "1px", mt: 0.2, bgcolor: "#000", width: "5%" }}
              />
            </Link>
            <Link to="/lemonade">
              <Typography
                data-aos="fade-left"
                sx={{
                  fontFamily: "itim",
                  fontSize: "30px",
                  mt: 3,
                  color: "#333",
                }}
              >
                Lemonade Podcast
              </Typography>
              <Box sx={{ height: "2px", bgcolor: "#000", width: "20%" }} />
              <Box
                sx={{ height: "1px", mt: 0.2, bgcolor: "#000", width: "10%" }}
              />
            </Link>
            <Link to="/afro-kinky">
              <Typography
                data-aos="fade-left"
                sx={{
                  fontFamily: "itim",
                  fontSize: "30px",
                  mt: 3,
                  color: "#333",
                }}
              >
                Afro kinky
              </Typography>
              <Box sx={{ height: "2px", bgcolor: "#000", width: "20%" }} />
              <Box
                sx={{ height: "1px", mt: 0.2, bgcolor: "#000", width: "10%" }}
              />
            </Link>
            <a href="https://thelemonadecoach.medium.com/" target="_blank">
              <Typography
                data-aos="fade-left"
                sx={{
                  fontFamily: "itim",
                  fontSize: "30px",
                  mt: 3,
                  color: "#333",
                }}
              >
                Blog
              </Typography>
              <Box sx={{ height: "2px", bgcolor: "#000", width: "20%" }} />
              <Box
                sx={{ height: "1px", mt: 0.2, bgcolor: "#000", width: "10%" }}
              />
            </a>
          </Box>
        </Box>
      </Drawer>
      <Box>
        <IconButton onClick={() => setOpen(true)}>
          <MenuIcon sx={{ color: "#000" }} />
        </IconButton>
      </Box>
    </>
  );
};

export default Drawers;
