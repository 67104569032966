

import React from 'react';
import ReactPlayer from 'react-player';

import { Box } from '@mui/material';

const Video = () => {
  return (
    <>
    <Box sx={{display:'flex', flexDirection:{md:'row', xs:'column'}, justifyContent:'space-between',alignItems:'center', margin:'0 auto', width:{md:'75%', xs:'95%'}, mt:14}}>
        <Box sx={{bgcolor:'#000', display:'grid', placeItems:'center', height:'300px'}}>
        <ReactPlayer
        url={"https://res.cloudinary.com/dywbpyexo/video/upload/v1738048012/vid2_guzeoq.mov"}
        controls
        width="50%"
        height="300px"
       
      />
        </Box>
  
      <Box sx={{bgcolor:'#000', display:'grid', placeItems:'center', height:'300px'}}>
      <ReactPlayer
        url={"https://res.cloudinary.com/dywbpyexo/video/upload/v1738047956/vid1_tydlzq.mov"}
        controls
        width="50%"
       height="300px"
      />
      </Box>
  
</Box>
  </>
  )
}

export default Video