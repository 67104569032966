import React from "react";
import Navbar from "./Navbar";
import { Box, Typography } from "@mui/material";
import banerImg from "../../assets/Img/Group 1 2.webp";

const Banner = () => {
  return (
    <>
      <Navbar />
      <Box
        className="banner"
        sx={{
          height: "100vh",
          backgroundImage: `url('${banerImg}')`,
          backgroundSize: {md:"2200px", xs:'900px'},
          backgroundRepeat: "no-repeat",
          backgroundPositionY: {md:"-50px", xs:'260px'},
          backgroundPositionX: "center",
          overflow:'hidden'
        }}
      >
<Box sx={{pt:15, margin:'0 auto', width:'60%'}}>

        <Typography sx={{pt:12, fontSize:'30px', fontFamily:'itim', fontWeight:700}}>I am <span style={{color:'#009872'}}>Jacky</span> <span style={{color:'#D76A25'}}>Jasper</span></Typography>
</Box>
      </Box>
    </>
  );
};

export default Banner;
