import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Event = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
   setIsOpen(true);
   setCurrentImageIndex(index);
    console.log(index, currentImageIndex)
  };

  return (
    <>
      <Box sx={{ mt: 8 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "itim",
            fontSize: "50px",
            fontWeight: 700,
          }}
        >
          Events
        </Typography>
        <Typography
          sx={{
            mt: 4,
            fontWeight: 700,
            fontSize: "20px",
            textDecoration: "underline",
          }}
        >
          The Lemonade Hub Series 1{" "}
        </Typography>
        <Typography sx={{ mt: 2, lineHeight: "28px" }}>
          The Lemonade Hub Series 1 is our premiere outreach project of the
          Lemonade Podcast with Jacky targeted at providing a holistic support
          for the Lemonade community. The Hub series will be collaborating with
          thought leaders and professionals to provide mental wellbeing,
          financial and other relevant support to our finalists of 10 women who
          are between the age range of 30 -55 years and are currently doing
          business in the city of Owerri. These women are of the Christian
          faith, married with children, a few are widowed, earned their O' level
          certificates, and some have completed their Bachelor's degree. We will
          work with these women through the next 12 calendar months to provide
          support and will be closing out this project cycle by October
        </Typography>

        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            marginTop: "30px",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/EzR2nNVyzC0"
            title="YouTube video"
            frameBorder="0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
            gap: "5px",
            marginTop: "60px",
          }}
        >
          {EventImages.map((image, index) => (
            <>
              <img
                key={index}
                src={image}
                alt={`Cloudinary ${index}`}
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                  borderRadius: "8px",
                  // boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
                onClick={() => {
                 setCurrentImageIndex(parseFloat(index) )
                setIsOpen(true)
                }}
              />
              {index === 3 && (
                <Box
                  sx={{
                    bgcolor: "#addb36",
                    display: "grid",
                    placeContent: "center",
                    minHeight:'300px',
                    borderRadius:'8px'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "70px",
                      color: "primary.main",
                      textAlign: "center",
                      fontFamily: "itim",
                      lineHeight: "40px",
                    }}
                  >
                    Picture Perfect
                  </Typography>
                </Box>
              )}
              {index === 23 && (
                <Box
                  sx={{
                    bgcolor: "#000",
                    display: "grid",
                    placeContent: "center",
                                 minHeight:'300px',
                           borderRadius:'8px'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "70px",
                      color: "#ffb600",
                      textAlign: "center",
                      fontFamily: "itim",
                      lineHeight: "40px",
                    }}
                  >
                    Picture Perfect
                  </Typography>
                </Box>
              )}
              {index === 3 && (
                <Box
                  sx={{
                    bgcolor: "#009871",
                    display: "grid",
                    placeContent: "center",
                                       minHeight:'300px',
                           borderRadius:'8px'
                  }}
                  align="center"
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "40px",
                      color: "#bcfa57",
                      textAlign: "center",
                      fontFamily: "itim",
                      lineHeight: "46px",
                    }}
                  >
                    Lemonade Hub Series 1 Memories
                  </Typography>
                </Box>
              )}
              {index === 25 && (
                <Box
                  sx={{
                    bgcolor: "#ffb600",
                    display: "grid",
                    placeContent: "center",
                                       minHeight:'300px',
                           borderRadius:'8px'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "40px",
                      color: "#000",
                      textAlign: "center",
                      fontFamily: "itim",
                      lineHeight: "46px",
                    }}
                  >
                    Lemonade Hub Series 1 Memories
                  </Typography>
                </Box>
              )}
            </>
          ))}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={EventImages[currentImageIndex]}
            nextSrc={EventImages[(currentImageIndex + 1) % EventImages.length]}
            prevSrc={
              EventImages[
                (currentImageIndex + EventImages.length - 1) %
                  EventImages.length
              ]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setCurrentImageIndex(
                (currentImageIndex + EventImages.length - 1) %
                  EventImages.length
              )
            }
            onMoveNextRequest={() =>
              setCurrentImageIndex((currentImageIndex + 1) % EventImages.length)
            }
          />
        )}
      </Box>
    </>
  );
};

export default Event;

const EventImages = [
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021997/30_qoqrk6.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021997/28_axphic.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021997/29_ugpuq8.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021996/27_qglzy9.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021994/25_ru3fbl.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021993/24_faajev.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021993/22_dc9hux.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021993/23_eeidzb.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021993/23_eeidzb.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/21_a9djq4.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/20_sk9iwl.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/18_qd2yzr.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/19_jkoimr.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/16_rsdgdv.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/17_uaoj0b.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/15_giaiog.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021992/14_dwnv9c.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/5_wgz19m.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/13_bjgsb6.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/12_qu0qp9.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/8_yxgp0b.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/11_ewqdgp.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/6_pipwid.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021991/7_sytrc8.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/2_eovdyw.webp",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/3_iqonqz.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/10_r8srwn.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/4_chvpqi.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/9_kspx0z.jpg",
  "https://res.cloudinary.com/dywbpyexo/image/upload/v1738021990/1_obou9m.jpg",
];
