import React, {useEffect} from "react";
import Navbar from "../Components/Common/Navbar";
import Banner from "../Components/Common/Banner";
import { Typography, Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";

import { PopupWidget } from "react-calendly";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import imgbutton from '../assets/Img/but.svg'
import backLogo from '../assets/Img/image (4).svg'
import logo from '../assets/Img/logo2.svg'
import button1 from '../assets/Img/youtube.svg'
import button2 from '../assets/Img/spotify.svg'
import bookback from '../assets/Img/bookback.svg'
import her from '../assets/Img/image 1.png'
import portrait from '../assets/Img/0X8A9309 copy 1.webp'
import mckup from '../assets/Img/mockup.webp'


const Main = () => {
  const theme= useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    useEffect(() => {
      Aos.init({ duration: 1000 });
    }, []);
  return (
    <>
      <Banner />
      <Box
       
        sx={{
          backgroundColor: "#BDFA57",
          // height: "500px",
          backgroundImage: `url('${backLogo}')`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: {md:"1200px", xs:'right'},
          backgroundPositionY:{md:'initial', xs:'bottom'},
          backgroundSize:{md:'initial', xs:'50px'}
        }}
      >
        <Box align="center">
          <Button
            sx={{
              backgroundColor: "#D76A25",
              color: "#fff",
              px: 0,
              borderRadius: "40px",
              mt: -4,
              py: 0,
              width: "200px",
              transition: "all 0.2s linear",
              "&:hover": {
                backgroundColor: "#000",
                backgroundImage: `url('${imgbutton}')`,
                backgroundSize: "contain",
                backgroundPositionY: "center",
                fontWeight: 700,
              },
            }}
          >
            <Box
              sx={{
                bgcolor: "#D76A25b7",
                width: "200px",
                borderRadius: "40px",
                fontFamily:'itim',
                py: 2,
                transition: "all 0.2s linear",
                "&:hover": {
                  backgroundColor: "#000000b7",
                
                  fontWeight: 700,
                  width: "150px",
                },
              }}
            >
              View Profile
            </Box>
          </Button>
        </Box>

        <Box sx={{ margin: "0 auto", width: "85%", mt: 7, pb:7 }}>
          <img src={logo} width={300} />

          <Box sx={{ mt: 3 }}>
            <a
              href="https://www.youtube.com/@thelemonadepodcastwithjacky?si=FGY_V0iT1bOsDJDf"
              target="_blank"
            >
              <Button >
                <img src={button1} width={120} />
              </Button>
            </a>
            <a
              href="https://open.spotify.com/show/3ZS9MQu5VZDXpUw9oz4m7f?si=qbIqyYteRKe4rFt4JNkwRw"
              target="_blank"
            >
              <Button>
                <img src={button2} width={150} />
              </Button>
            </a>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          background: `url('${bookback}')`,
          backgroundSize: "contain",
          // height: "700px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box sx={{ margin: "0 auto", width: "100%" }}>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Box sx={{height:{md:'600px', xs:'300px'}, display:'grid', placeItems:'center', px:2}}>
                <Typography  data-aos="fade-up" sx={{ fontSize: {md:"50px", xs:'35px'} ,lineHeight:{md:'60px', xs:'50px'},    fontWeight: 200, textTransform:'uppercase'}}>
                  Grab Your <br/>Copy of <br />
                  <span style={{fontWeight:700}}>my bestselling</span>
                  <br /> book
                  <Link to="/afro-kinky">
                <Button sx={{color:'', fontFamily:'itim', ml:3}}> More Info</Button>
                </Link>
                </Typography>
               
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box  data-aos="fade-right" sx={{width:'100%', background:`url('${mckup}')`, height:{md:'600px', xs:'200px'}, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}>

              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <Grid container sx={{display:'flex', flexDirection:{md:'row', xs:'column-reverse'}}}>
        <Grid item md={6} xs={12}>
            <Box
              sx={{
                background: `url('${her}')`,
                width: "100%",
                height: "500px",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                bgcolor: "#D76A25",
                height: "500px",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography
              data-aos="fade-right"
                sx={{
                  fontFamily: "itim",
                  color: "#fff",
                  fontSize: "35px",
                  fontWeight: 700,
                  textAlign:'center'
                }}
              >
                “when life throws <br />
                lemons at you, turn <br />
                them into lemonade”
              </Typography>
            </Box>
          </Grid>
         
        </Grid>
      </Box>
      <Box
      data-aos="fade-right"
        sx={{
          background: `url('${bookback}')`,
          backgroundSize: "contain",

          display: "grid",
          placeItems: "center",
        }}
      >
        <Box sx={{ margin: "0 auto", width:{md: "75%", xs:'100%'}, pt:5 }}>
          <Grid container sx={{display:'flex', flexDirection:{md:'row', xs:'column-reverse'}}}>
            <Grid item md={6} xs={12}>
              <Box
              //  data-aos="zoom-out"
                sx={{
                  backgroundImage: `url('${portrait}')`,
                  height: "500px",
                  backgroundSize: "cover",
                  backgroundPositionX: "center",
                 
                }}
              />
            </Grid>
            <Grid item md={1} xs={0}></Grid>
            <Grid item md={5} xs={12}>
              <Box
                sx={{ height: {md:"500px", xs:"300px"}, display: "grid", placeItems: "center" , p:2}}
              >
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'left'}}>
                  <Typography sx={{fontSize:'35px', fontWeight:500, fontFamily:'itim'}}>
                    Book a free consulting session with me{" "}
                  </Typography>
                  <div style={{ position: 'absolute', top: '80%', left: '50%', transform: 'translate(-50%, -50%)', width:'50%' }}>
        <PopupWidget
        url="https://calendly.com/lemonade-coach/30min"
       
        rootElement={document.getElementById("root")}
        text="Book Now"
        textColor="#ffffff"
        color="#009872"
      />
        </div>
      
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    
    </>
  );
};

export default Main;
